/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Column } from '@audacious/components/components/Grid';
import Chip from '@audacious/components/components/Chip';
import Button from '@audacious/components/components/Button';
import IconButton from '@audacious/components/components/IconButton';
import { faPrint } from '@audacious/icons/solid/faPrint';
import { faArrowDownToLine } from '@audacious/icons/regular/faArrowDownToLine';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import {
    RecordsPerPageSelector,
    PageNavigation,
} from '@audacious/components/components/Pagination';
import { PageContainerGroup } from '@audacious/components/components/Page';
import { SimpleTable } from '@audacious/components/components/Table';
import SelectedOverview from '@audacious/components/components/SelectedOverview';
import Spinner from '@audacious/components/components/Spinner';
import Data, { DataTextProperty } from '@audacious/components/components/Data';
import { Text } from '@audacious/components/components/Typography';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import cloneDeep from 'lodash/cloneDeep';
import { clone } from 'lodash';
import {
    startCheckOut,
    startDestination,
} from '../../../actions/check-out-actions';

import searchDataPropType from './search-data-prop-type';
import selectedPatientsPropType from './selected-patients-prop-type';
import {
    INVALID_VALUE_MSG,
    INVALID_CHARACTERS_MSG,
} from '../../../constants/error-messages';
import { namePattern } from '../../../constants/reg-ex-patterns';
import { clearStores } from '../../../actions/home-actions';
import runSearchQuery from '../../../actions/run-search-query';

import './check-out.scss';

const PAGE_SIZE_OPTIONS = [20, 50, 100, 200];
const DEFAULT_PAGINATION = {
    page: 1,
    pageSize: 100,
};

const columns = [
    {
        headerBody: 'Name',
        cellValuePath: 'formatted.nameLastFirst',
        width: '215px',
        sortable: true,
        bold: true,
    },
    {
        headerBody: 'DOB',
        cellValuePath: 'formatted.dobString',
        width: '130px',
    },
    {
        headerBody: 'Gender',
        cellValuePath: 'formatted.gender',
        width: '100px',
    },
    {
        headerBody: 'Address',
        cellValuePath: 'formatted.fullAddress',
    },
    {
        headerBody: 'Phone',
        cellValuePath: 'formatted.phone',
        width: '196px',
    },
    {
        headerBody: 'Check-In date',
        cellValuePath: 'formatted.checkInDate',
        width: '170px',
    },
];

const baseValue = {
    search: null,
};

class CheckOutStart extends React.Component {
    constructor(props) {
        super(props);

        this.dataRef = React.createRef();

        this.handleValidateStart = this.handleValidateStart.bind(this);
        this.handleValidateEnd = this.handleValidateEnd.bind(this);
        this.handleExecuteStart = this.handleExecuteStart.bind(this);
        this.handleExecute = this.handleExecute.bind(this);
        this.handleExecuteSearch = this.handleExecuteSearch.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.renderTopPaginationControls = this.renderTopPaginationControls.bind(
            this,
        );
        this.renderBottomPaginationControls = this.renderBottomPaginationControls.bind(
            this,
        );
        this.renderLoadingChip = this.renderLoadingChip.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.handleGotoPage = this.handleGotoPage.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
        this.handlePrintClick = this.handlePrintClick.bind(this);

        let {
            searchData: { query: initialValue },
        } = props;

        if (isNil(initialValue)) {
            initialValue = baseValue;
        }

        this.state = {
            initialValue,
            selected: {},
            working: false,
        };
    }

    componentDidMount() {
        const {
            initialSelected,
            tenantId,
            facilityId,
            fluxibleContext: { executeAction },
        } = this.props;

        this.setState({
            selected: !isNil(initialSelected) ? initialSelected : {},
        });

        executeAction(startCheckOut);

        if (facilityId) {
            executeAction(runSearchQuery, {
                options: { tenantId, facilityId },
                newPagination: DEFAULT_PAGINATION,
                searchData: {},
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { facilityId: prevFacilityId } = prevProps;
        const {
            facilityId,
            tenantId,
            fluxibleContext: { executeAction },
        } = this.props;

        if (!prevFacilityId && facilityId) {
            executeAction(runSearchQuery, {
                options: { tenantId, facilityId },
                newPagination: DEFAULT_PAGINATION,
                searchData: {},
            });
        }
    }

    handleValidateStart() {
        this.setState({
            working: true,
        });
    }

    handleValidateEnd() {
        this.setState({
            working: false,
        });
    }

    // eslint-disable-next-line class-methods-use-this
    handleExecuteStart(value, results) {
        if (!isNil(results)) {
            return false;
        }

        return true;
    }

    handleExecute(data) {
        this.setState({
            selected: {},
        });

        const {
            tenantId,
            facilityId,
            fluxibleContext: { executeAction },
        } = this.props;

        this.searchTerm = data.search || '';

        executeAction(runSearchQuery, {
            options: { tenantId, facilityId },
            newPagination: DEFAULT_PAGINATION,
            search: data.search,
        });
    }

    handleExecuteSearch() {
        this.dataRef.current.execute();
    }

    handleNextClick() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        const { selected } = this.state;

        const selectedPatients = mapValues(selected, item => {
            const copy = cloneDeep(item);

            copy.destination = {
                type: 'home',
            };

            return copy;
        });

        executeAction(startDestination, selectedPatients);
    }

    handleCancelClick() {
        const {
            history,
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(clearStores);
        history.push('/home');
    }

    handleExportClick() {
        const {
            tenantId,
            facilityId,
            userName,
            facilityName,
            locationName,
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(runSearchQuery, {
            options: {
                tenantId,
                facilityId,
                userName,
                facilityName,
                locationName,
                exportType: 'csv',
            },
            newPagination: DEFAULT_PAGINATION,
            search: !isNil(this.searchTerm) ? this.searchTerm : '',
        });
    }

    handlePrintClick() {
        const {
            tenantId,
            facilityId,
            userName,
            facilityName,
            locationName,
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(runSearchQuery, {
            options: {
                tenantId,
                facilityId,
                userName,
                facilityName,
                locationName,
                exportType: 'print',
            },
            newPagination: DEFAULT_PAGINATION,
            search: !isNil(this.searchTerm) ? this.searchTerm : '',
        });
    }

    handleChangePageSize(newPageSize, newPage) {
        const {
            tenantId,
            facilityId,
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(runSearchQuery, {
            options: { tenantId, facilityId },
            isInitial: false,
            newPagination: {
                pageSize: newPageSize,
                page: newPage,
            },
        });
    }

    handleGotoPage(newPage) {
        const {
            tenantId,
            facilityId,
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(runSearchQuery, {
            options: { tenantId, facilityId },
            isInitial: false,
            newPagination: {
                page: newPage,
            },
        });
    }

    renderTopPaginationControls() {
        const {
            pagination,
            totalNumberOfCheckIns,
            searchData,
            allowPrint,
        } = this.props;

        const exportButtonsRender = allowPrint ? (
            <>
                <IconButton
                    id="patients-export-button"
                    className="export-button"
                    icon={faArrowDownToLine}
                    size="sm"
                    onClick={this.handleExportClick}
                    aria-label="Export CSV"
                />
                <IconButton
                    id="patients-print-button"
                    className="export-button"
                    icon={faPrint}
                    size="sm"
                    onClick={this.handlePrintClick}
                    aria-label="Print"
                />
            </>
        ) : null;

        return (
            <>
                <RecordsPerPageSelector
                    numRecords={totalNumberOfCheckIns}
                    currentPage={pagination.page}
                    recordsPerPage={pagination.pageSize}
                    recordsPerPageOptions={PAGE_SIZE_OPTIONS}
                    disabled={searchData.running}
                    onChangeRecordsPerPage={this.handleChangePageSize}
                />
                <PageNavigation
                    numRecords={totalNumberOfCheckIns}
                    currentPage={pagination.page}
                    recordsPerPage={pagination.pageSize}
                    onGotoPage={this.handleGotoPage}
                    disabled={searchData.running}
                    firstAndLastNav
                />
                {exportButtonsRender}
            </>
        );
    }

    renderBottomPaginationControls() {
        const { pagination, totalNumberOfCheckIns, searchData } = this.props;

        if (
            isNil(searchData) ||
            isNil(searchData.results) ||
            searchData.results.length <= 0
        ) {
            return null;
        }

        return (
            <>
                <PageNavigation
                    numRecords={totalNumberOfCheckIns}
                    currentPage={pagination.page}
                    recordsPerPage={pagination.pageSize}
                    onGotoPage={this.handleGotoPage}
                    disabled={searchData.running}
                    numJumpPages={7}
                    jumpToPageNav
                    firstAndLastNav
                />
                <RecordsPerPageSelector
                    numRecords={totalNumberOfCheckIns}
                    currentPage={pagination.page}
                    recordsPerPage={pagination.pageSize}
                    recordsPerPageOptions={PAGE_SIZE_OPTIONS}
                    disabled={searchData.running}
                    onChangeRecordsPerPage={this.handleChangePageSize}
                />
            </>
        );
    }

    renderLoadingChip() {
        const { searchData } = this.props;

        if (!searchData.running) {
            return null;
        }

        return (
            <Chip
                className="loading-alert"
                color="grey"
                shade="darker"
                variant="pill"
                size="xl"
            >
                Loading...
            </Chip>
        );
    }

    renderTable() {
        const { searchData } = this.props;

        const { selected } = this.state;

        if (isNil(searchData.results)) {
            return null;
        }

        if (searchData.results.length <= 0) {
            return (
                <>
                    <h2 className="check-out-no-results-header">
                        No Results Found
                    </h2>
                    <span className="check-out-no-results-message">
                        Check to see if the person’s name is spelled correctly
                        and try again.
                    </span>
                </>
            );
        }

        return (
            <SimpleTable
                items={searchData.results}
                columns={columns}
                alwaysShowHeaders
                minWidth={1048}
                initialSortPath="name"
                initialSortDescending
                selectable
                stickyColumns={2}
                onRowSelect={item => {
                    const copy = clone(selected);

                    if (!isNil(copy[item.id])) {
                        delete copy[item.id];
                    } else {
                        copy[item.id] = item;
                    }

                    this.setState({
                        selected: copy,
                    });
                }}
                isRowSelected={item => !isNil(selected[item.id])}
            />
        );
    }

    render() {
        const { searchData, isExporting } = this.props;

        const { selected, working, initialValue } = this.state;

        const selectedCount = keys(selected).length;

        let selectedElement = null;

        if (selectedCount > 0) {
            selectedElement = (
                <SelectedOverview
                    className="selected-patients"
                    buttonText="Next"
                    id="selected-count"
                    numSelected={selectedCount}
                    onButtonClick={this.handleNextClick}
                />
            );
        }

        return (
            <div className="check-out-start">
                <PageContainerGroup>
                    {isExporting ? <Spinner variant="page" /> : null}
                    <Data
                        ref={this.dataRef}
                        baseValue={initialValue}
                        validateOnExecute
                        showResultsOnTouch
                        showResultsOnExecute
                        onValidateStart={this.handleValidateStart}
                        onValidateEnd={this.handleValidateEnd}
                        onExecuteStart={this.handleExecuteStart}
                        onExecute={this.handleExecute}
                        disabled={working || searchData.running}
                    >
                        <Row gutter="16">
                            <Column width="fill">
                                <Text size="xl" weight="bold">
                                    Select to Check Out
                                </Text>
                            </Column>
                        </Row>
                        <Row gutter="16" enableAfter>
                            <Column width="3">
                                <DataTextProperty
                                    id="search-term"
                                    aria-label="Search Term"
                                    width="xsm"
                                    path="search"
                                    size="sm"
                                    minLength={[2, INVALID_VALUE_MSG]}
                                    maxLength={[50, INVALID_VALUE_MSG]}
                                    pattern={[
                                        namePattern,
                                        INVALID_CHARACTERS_MSG,
                                    ]}
                                    focusOnMount
                                    leftIcon={{
                                        icon: faMagnifyingGlass,
                                    }}
                                    onClear={this.handleExecuteSearch}
                                />
                            </Column>
                            <Column width="content">
                                <Button
                                    id="checkOutSearch"
                                    className="check-out-search-button"
                                    color="secondary"
                                    size="sm"
                                    variant="outline"
                                    onClick={this.handleExecuteSearch}
                                    disabled={working || searchData.running}
                                >
                                    Search
                                </Button>
                            </Column>
                            <Column
                                width="fill"
                                className="top-pagination-controls-column"
                            >
                                {this.renderTopPaginationControls()}
                            </Column>
                        </Row>
                    </Data>

                    {this.renderLoadingChip()}
                </PageContainerGroup>

                {this.renderTable()}
                {selectedElement}

                <PageContainerGroup>
                    <Row gutter="16">
                        <Column
                            width="fill"
                            className="botton-pagination-controls-column"
                        >
                            {this.renderBottomPaginationControls()}
                        </Column>
                    </Row>
                    <Row gutter="16">
                        <Column width="fill" />
                        <Column width="content">
                            <Button
                                id="checkOutCancel"
                                color="secondary"
                                variant="opaque"
                                onClick={this.handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </Column>
                    </Row>
                </PageContainerGroup>
            </div>
        );
    }
}

CheckOutStart.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    searchData: searchDataPropType.isRequired,
    pagination: PropTypes.objectOf(PropTypes.string).isRequired,
    totalNumberOfCheckIns: PropTypes.number.isRequired,
    initialSelected: selectedPatientsPropType,
    tenantId: PropTypes.string.isRequired,
    facilityId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    facilityName: PropTypes.string.isRequired,
    isExporting: PropTypes.bool.isRequired,
    allowPrint: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
    }).isRequired,
};

CheckOutStart.defaultProps = {
    initialSelected: null,
};

export default withRouter(applyFluxibleContext(CheckOutStart));
