/* eslint-disable react/no-access-state-in-setstate */
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import formatDate from 'date-fns/format';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import formatPatient from '../common/util/format-patient';

const initialState = {
    step: null,
    searchData: {
        running: false,
        results: null,
    },
    pagination: {
        page: 1,
        pageSize: 100,
    },
    queryTotal: null,
    selectedPatients: {},
    isCommitting: false,
    isExporting: false,
};

class CheckOutWorkflowStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    startCheckOut() {
        this.setState({
            ...initialState,
            step: 'started',
            isCommitting: false,
        });
    }

    patientSearchStart(query) {
        if (this.state.step !== 'started') {
            throw new Error('Invalid check out step');
        }

        this.setState({
            ...this.state,
            searchData: {
                ...this.state.searchData,
                query,
                running: true,
                error: null,
            },
        });
    }

    patientSearchFinish({ results, page, pageSize }) {
        const { checkins, total: queryTotal } = results;

        if (this.state.step !== 'started') {
            throw new Error('Invalid check out step');
        }

        const processed = map(checkins, patient => 
            // eslint-disable-next-line no-param-reassign
             ({
                id: patient.mrn,
                patient,
                formatted: formatPatient(patient),
            })
        );

        this.setState({
            ...this.state,
            searchData: {
                ...this.state.searchData,
                running: false,
                error: null,
                results: processed,
            },
            queryTotal,
            pagination: {
                ...this.state.pagination,
                page,
                pageSize,
            },
        });
    }

    patientSearchFail(error) {
        if (this.state.step !== 'started') {
            throw new Error('Invalid check out step');
        }

        this.setState({
            ...this.state,
            searchData: {
                ...this.state.searchData,
                running: false,
                error,
            },
            queryTotal: null,
        });
    }

    startDestination(selectedPatients) {
        this.setState({
            ...this.state,
            step: 'destination',
            selectedPatients,
        });
    }

    cancelDestination() {
        this.setState({
            ...this.state,
            step: 'started',
        });
    }

    startReview(selectedPatients) {
        const patients = mapValues(selectedPatients, patient => {
            const {
                destination: { deceased },
            } = patient;

            return {
                ...patient,
                destination: {
                    ...patient.destination,
                    deceasedFormatted: !isNil(deceased)
                        ? formatDate(deceased, 'MM/dd/yyyy')
                        : null,
                },
            };
        });

        this.setState({
            ...this.state,
            step: 'review',
            selectedPatients: patients,
        });
    }

    cancelReview() {
        this.setState({
            ...this.state,
            step: 'destination',
        });
    }

    startConfirmation() {
        this.setState({
            ...this.state,
            step: 'confirmation',
        });
    }

    checkOutPatientsStart() {
        this.setState({
            isCommitting: true,
        });
    }

    checkOutPatientsFinish([error]) {
        const newState = {
            error,
            isCommitting: false,
        };

        if (isNil(error)) {
            newState.step = null;
        }

        this.setState(newState);
    }

    patientExportStart() {
        this.setState({
            ...this.state,
            isExporting: true,
        });
    }

    patientExportFinish() {
        this.setState({
            ...this.state,
            isExporting: false,
        });
    }

    getWorkflowStep() {
        return this.getState().step;
    }

    getSearchData() {
        return this.state.searchData;
    }

    getSelectedPatients() {
        return this.state.selectedPatients;
    }

    getPagination() {
        return this.state.pagination;
    }

    getTotal() {
        return this.state.queryTotal;
    }

    getIsExporting() {
        return this.state.isExporting;
    }

    isCommitting() {
        return this.state.isCommitting;
    }

    getError() {
        return this.state.error;
    }
}

CheckOutWorkflowStore.storeName = 'CheckOutWorkflowStore';
CheckOutWorkflowStore.handlers = {
    CHECK_OUT_START: 'startCheckOut',
    CHECK_OUT_START_DESTINATION: 'startDestination',
    CHECK_OUT_CANCEL_DESTINATION: 'cancelDestination',
    CHECK_OUT_START_REVIEW: 'startReview',
    CHECK_OUT_CANCEL_REVIEW: 'cancelReview',
    CHECK_OUT_START_CONFIRMATION: 'startConfirmation',
    CHECK_OUT_PATIENTS_START: 'checkOutPatientsStart',
    CHECK_OUT_PATIENTS_FINISH: 'checkOutPatientsFinish',
    CHECK_OUT_SEARCH_START: 'patientSearchStart',
    CHECK_OUT_SEARCH_SUCCESS: 'patientSearchFinish',
    CHECK_OUT_SEARCH_FAILED: 'patientSearchFail',
    CLEAR_STORES: 'resetState',
    LOGOUT: 'resetState',
    CHECK_OUT_PATIENT_EXPORT_START: 'patientExportStart',
    CHECK_OUT_PATIENT_EXPORT_FINISH: 'patientExportFinish',
};

export default CheckOutWorkflowStore;
